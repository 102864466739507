<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Resep Obat</b> yang ada di Klinik Hayandra
      </div>
      <b-button
        squared
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/prescriptions/add')"
      >Buat Resep Baru</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table
              :items="items"
              :fields="fields"
              :hasPatient="false"
              :perPage="perPage"
              :currentPage="currentPage"
              :totalRows="totalRows"
              @filterByPeriode="generalFilter"
              @filterByDoctor="generalFilter"
              @filterByPatient="generalFilter"
              @btnDeleteOnClick="btnDeleteOnClick"
              @pageOnClick="pageOnClick"
            />
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Table from '@/component/prescriptions/Table.vue'
import module from "@/core/modules/CrudModule.js"
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import { getUser } from '@/core/services/jwt.service.js'

export default {

  components: {
    Card,
    Table
  },

  data() {
    return {
      // Filter
      filter: {
        doctor_id: "",
        patient_name: "",
        start_date: "",
        end_date: "",
        // start_date: new Date().toISOString().substr(0, 10),
        // end_date: new Date().toISOString().substr(0, 10),
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "doctor_name",
          label: "Dokter",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "display_medicine",
          label: "Obat",
          sortable: true,
        },
        { key: "actions", label: "Aksi", thClass: "th-action-prescription" },
      ],
      // Table Items
      items: [],
      // access management
      manipulateBtn: false,
      // current user
      currentUser: getUser()
    }
  },

  methods: {

    pageOnClick(page) {
      this.currentPage = page
      this.pagination()
    },

    async pagination() {
      let route
      if (this.currentUser.user_type_id == 1) {
        route = `prescriptions/get-by-doctor/${this.currentUser.user_id}`
      } else {
        route = 'prescriptions'
      }
      let filterParams = `&doctor_id=${this.filter.doctor_id}&patient_name=${this.filter.patient_name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let response = await module.paginate(route, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.totalRows = pagination.total

      let a, b
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a
      }
      this.items = response.data
    },

    generalFilter(sended) {
      this.filter.doctor_id = sended.doctor_id
      this.filter.start_date = sended.start_date
      this.filter.end_date = sended.end_date
      this.filter.patient_name = sended.patient_name
      this.pagination()
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete("prescriptions/" + id)
      // If Deleted
      if (result) {
        this.pagination()
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      let a
      for (a = 0; a < access_right.length; a++) {
        console.log("looping")
        if (access_right[a] == "8002") {
          this.manipulateBtn = true
        }
      }
    },
    
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Daftar Resep Obat" }])
    // Get Table Data
    this.pagination()
    this.setActiveMenu()
  },

}
</script>